<template>
  <v-app>
    <v-app-bar app color="white">
      <v-toolbar-title class="pl-0">{{ $route.meta.pageName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            v-bind="attrs"
            v-on="on"
            small
            color="white"
            class="px-1"
          >
            <img
              v-if="$i18n.locale === 'vi'"
              src="@/assets/sidebars/vietnam.png"
              alt=""
              style="height: 32px"
            />
            <img
              v-else
              src="@/assets/sidebars/usa.png"
              alt=""
              style="height: 32px"
            />
            <v-icon small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            class="cursor-pointer"
            @click="changeLanguage('vi')"
            :class="{ 'primary white--text': $i18n.locale === 'vi' }"
          >
            <v-list-item-title>
              <div class="d-flex align-center">
                <img
                  src="@/assets/sidebars/vietnam.png"
                  alt=""
                  style="height: 22px"
                  class="mr-1"
                />
                <span>Tiếng Việt</span>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            class="cursor-pointer"
            @click="changeLanguage('en')"
            :class="{ 'primary white--text': $i18n.locale === 'en' }"
          >
            <v-list-item-title>
              <div class="d-flex align-center">
                <img
                  src="@/assets/sidebars/usa.png"
                  alt=""
                  style="height: 22px"
                  class="mr-1"
                />
                <span>English</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <div class="bg-img"></div>

    <v-container class="w-100 h-100 d-flex align-center justify-center">
      <div>
        <div class="text-h5 mb-3 text-center">{{ $t("labels.register") }}</div>
        <v-card
          outlined
          max-width="420px"
          min-width="360px"
          class="mx-auto"
          v-if="!isSuccess"
        >
          <v-card-text v-if="!accountType">
            <v-autocomplete
              v-model.number="accountType"
              class="c-input-small"
              :items="accountTypeOptions"
              autocomplete="off"
              :label="$t('labels.you_are_individual_or_business')"
              outlined
              dense
              hide-details
            ></v-autocomplete>
          </v-card-text>
          <v-card-text v-else>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="account.company_name"
                  class="c-input-small"
                  autocomplete="off"
                  :label="`${$t('labels.company_name')} *`"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="account.tax_id"
                  class="c-input-small"
                  autocomplete="off"
                  :label="`${$t('labels.tax_id')} *`"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="account.email"
                  class="c-input-small"
                  autocomplete="off"
                  :label="`${$t('labels.email')} *`"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="account.hotline"
                  class="c-input-small"
                  autocomplete="off"
                  :label="`${$t('labels.phone_number')} *`"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="account.password"
                  class="c-input-small"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :label="`${$t('labels.password')} *`"
                  counter
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <SelectCountry
                  name="id_country"
                  class="c-input-small"
                  :id-country="account.id_country"
                  @onChange="onChange"
                  ref="selectCountryRef"
                />
              </v-col>
              <v-col cols="12">
                <SelectCity
                  name="id_city"
                  class="c-input-small"
                  :id-country="account.id_country"
                  :id-city="account.id_city"
                  @onChange="onChange"
                  ref="selectCityRef"
                />
              </v-col>
              <v-col cols="12">
                <SelectCounty
                  class="c-input-small"
                  :id-city="account.id_city"
                  :id-county="account.id_county"
                  name="id_county"
                  @onChange="onChange"
                />
              </v-col>
              <v-col cols="12">
                <SelectWard
                  class="c-input-small"
                  :id-city="account.id_city"
                  :id-county="account.id_county"
                  :id-ward="account.id_ward"
                  name="id_ward"
                  @onChange="onChange"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  class="c-input-small"
                  v-model="account.address"
                  autocomplete="off"
                  :label="`${$t('labels.address')} *`"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
              <v-text-field v-model="account.email_cc" autocomplete="off" :label="`${$t('labels.email_report')} *`"
                outlined dense hide-details></v-text-field>
            </v-col> -->
              <v-col cols="12">
                <div class="d-flex align-center justify-start">
                  <v-checkbox
                    class="c-input-small mt-0 pt-0"
                    dense
                    hide-details=""
                    v-model="allowService"
                  ></v-checkbox>
                  <div>
                    {{ $t("labels.read_and_agree") }}
                    <a
                      href="/v2/services"
                      target="_blank"
                      class="primary--text text-decoration-underline"
                    >
                      {{ $t("labels.terms_of_use") }}
                    </a>
                    {{ $t("labels.by_ssc_eco") }}
                  </div>
                </div>
              </v-col>

              <v-spacer></v-spacer>
              <v-col cols="12">
                <v-btn
                  block
                  color="success"
                  @click="register"
                  :disabled="isDisabledBtn"
                >
                  {{ $t("labels.register") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card outlined class="mx-auto" v-else>
          <div class="text-center pa-5 fs-22px">
            <div>
              {{ $t("labels.customer_register_success") }}
              <a href="tel:0769995555">076 999 5555</a>
              {{ $t("labels.contact_to_use") }}
            </div>
            <div>
              {{ $t("labels.sincerely_thank") }}
            </div>
          </div>
        </v-card>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { compress } from "image-conversion";
import moment from "moment";
import { httpClient } from "@/libs/http";
import { ACCOUNT_TYPE_OPTIONS } from "@/libs/const";

export default {
  name: "CustomerRegister",
  components: {
    SelectCountry: () => import("@/components/common/SelectCountry"),
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
  },
  data: () => ({
    isSuccess: false,
    accountType: null,
    account: {
      company_name: null,
      tax_id: null,
      email: null,
      password: null,
      address: null,
      id_country: null,
      id_city: null,
      id_county: null,
      id_ward: null,
      hotline: null,
      // email_report: null,
    },
    uploadType: null,
    showPassword: false,
    isLoading: false,
    allowService: false,
    accountTypeOptions: [...ACCOUNT_TYPE_OPTIONS],
  }),
  computed: {
    isDisabledBtn() {
      if (this.isLoading) {
        return true;
      }
      if (!this.allowService) {
        return true;
      }
      if (
        !this.account.email ||
        !this.isEmailValid(this.account.email) ||
        !this.account.hotline ||
        !this.isPhoneValid(this.account.hotline)
      ) {
        return true;
      }
      if (!this.account.password || this.account.password.length < 6) {
        return true;
      }

      const keys = Object.keys(this.account);
      let isInvalid = false;
      keys.forEach((k) => {
        if (k == "tax_id") {
          if (!this.account[k] && this.accountType === 2) {
            isInvalid = true;
          }
        } else {
          if (!this.account[k]) {
            isInvalid = true;
          }
        }
      });
      return isInvalid;
    },
  },
  mounted() {},
  methods: {
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      let files = e.target.files || e.dataTransfer.files;
      if (files && files[0]) {
        const filePath = await this.uploadFile(files[0]);
        if (filePath) {
          this.account[
            this.uploadType
          ] = `${process.env.VUE_APP_FILE_CDN_URL}/${filePath}`;
        }
        this.$refs.inputUploadFile.value = null;
      }
      this.isLoading = false;
    },
    async uploadFile(file) {
      const resBlob = await compress(file, {
        quality: 0.85,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${moment().unix()}_${this.generateRandomString(10)}.jpg`,
        { type: "image/jpeg" }
      );
      let fd = new FormData();
      fd.append("file", newFile);

      const { data } = await httpClient.post(`/upload-image`, fd);
      return data;
    },
    async register() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/register-main-account", this.account);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.register_success"));
        const account = {
          company_name: null,
          email: null,
          password: null,
          address: null,
          id_city: null,
          id_county: null,
          id_ward: null,
          hotline: null,
          email_report: null,
        };
        this.account = { ...account };
        this.isSuccess = true;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    showImageUpload(type) {
      this.uploadType = type;
      this.$refs.inputUploadFile.click();
    },
    onChange(item) {
      this.account = { ...this.account, [item.name]: item.value };
    },

    changeLanguage(lang) {
      localStorage.setItem("_lang", lang);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-img {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("https://file.ssc.eco/background/bg1.png");
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 1;
  }
}

.container {
  position: relative;
  z-index: 2;
}

.text-h5 {
  color: #fff;
}
</style>
